
import { defineComponent } from '@vue/runtime-core'
import { DefaultTableExposed } from 'apptimizm-ui'
import { Ref, computed, ref } from 'vue'
import Table from './table.vue'
import { store } from '@/store/'

export default defineComponent({
  setup () {
    const table = ref(null) as unknown as Ref<DefaultTableExposed>
    const isProjectSelected = computed(() => !!store.currentProject.id)

    const tableFilter = {
      search: ref(''),
      get tableParams () {
        const res: { [code: string]: string } = {}
        if (store.currentProject.id) res.project = store.currentProject.id
        if (this.search.value) res.search = this.search.value

        return res
      }
    }

    return () => (
      <div>
        <div class="page-top-panel">
          <h1>Журнал</h1>
          <div class="page-top-panel-row mt-5">
          </div>
        </div>
        <div class="layout">
          {isProjectSelected.value ? <Table table={table} tableFilter={tableFilter}/> : null}
        </div>
      </div>
    )
  }
})
